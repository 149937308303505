.masonry {
	columns: 1;
	gap: 22px;
	height: max-content;
}

.masonry > * {
	break-inside: avoid;
	margin-bottom: 22px;
}

@media (min-width: 768px) {
	.masonry {
		columns: 2;
		gap: 25px;
	}

	.masonry > * {
		margin-bottom: 25px;
	}
}
