.scrollbar-custom::-webkit-scrollbar {
	width: 4px;
}

.scrollbar-custom::-webkit-scrollbar-track {
	background: #f5f5f7;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
	background-color: #2597dc;
	border-radius: 44px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
	background-color: #2597dc;
}
